.Container {
  padding: 20px;
  height: 100%;
  background: var(--bonver-green-primary);
  text-align: center;
}

.Title {
  font-size: 20px;
  color: var(--white);
  max-width: 400px;
}

.Link {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-size: 14px;
  min-height: 34px;
  font-weight: 600;
  color: var(--bonver-green-primary);
  padding: 0 16px;
  background-color: var(--white);
  border: 1px solid var(--white);
  border-radius: 8px;
  outline: none;
  min-width: 80px;
  transition: all 100ms ease-in-out;
  letter-spacing: -0.24px;
}
